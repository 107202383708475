import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import './css/Home.css';
import factoryImage from './images/factory.webp';


const Home = () => {
  const navigate = useNavigate();

  const handleGetStarted = () => {
    navigate('/preise');
  };

  return (
    <Container fluid className="home-section bg-dark text-light">
      <Row className="justify-content-center text-center hero-section py-5">
        <Col md={8}>
          <h1 className="fw-bold display-3">Erlebe Satisfactory wie nie zuvor!</h1>
          <p className="lead mt-4">
            Miete deinen eigenen Satisfactory Server und erlebe grenzenloses Bauen mit Freunden. Leistungsstark, sicher und jederzeit verfügbar.
          </p>
          <Button variant="warning" size="lg" onClick={handleGetStarted} className="mt-4">
            Jetzt Server mieten
          </Button>
        </Col>
      </Row>

      <Row className="features-section text-center py-5">
        <Col md={4}>
          <Card className="feature-card shadow-lg bg-secondary text-light p-4">
            <Card.Img variant="top" src={factoryImage} alt="Factory" />
            <Card.Body>
              <Card.Title>Hohe Leistung</Card.Title>
              <Card.Text>
                Profitiere von AMD Epyc CPUs und NVMe-SSDs – keine Lag-Spikes, nur reibungsloses Gameplay.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>

        <Col md={4}>
          <Card className="feature-card shadow-lg bg-secondary text-light p-4">
            <Card.Img variant="top" src="/images/friends.jpg" alt="Friends" />
            <Card.Body>
              <Card.Title>Spiele mit Freunden</Card.Title>
              <Card.Text>
                Bis zu 40 Spieler pro Server! Lade Freunde ein und baue riesige Fabriken gemeinsam.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>

        <Col md={4}>
          <Card className="feature-card shadow-lg bg-secondary text-light p-4">
            <Card.Img variant="top" src="/images/security.jpg" alt="Security" />
            <Card.Body>
              <Card.Title>Sicherheit und Stabilität</Card.Title>
              <Card.Text>
                Dank DDoS-Schutz und 24/7 Monitoring bleibt dein Server stets geschützt und online.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="about-section py-5 bg-secondary text-light">
        <Col md={6} className="text-center">
          <h3>Individuelle Servereinstellungen</h3>
          <p>
            Passe deinen Server flexibel an: Mehr RAM, mehr Speicherplatz, mehr Leistung. Wähle, was du brauchst.
          </p>
        </Col>
        <Col md={6} className="text-center">
          <h3>Skalierbare Lösungen</h3>
          <p>
            Upgraden leicht gemacht. Dein Server wächst mit deinen Anforderungen – für kleine Gruppen oder riesige Projekte.
          </p>
        </Col>
      </Row>

      <Row className="cta-section text-center py-5 bg-warning text-dark">
        <Col>
          <h2>Bereit zu starten?</h2>
          <p>
            Sichere dir noch heute deinen Satisfactory-Server. Einfach, schnell und zuverlässig.
          </p>
          <Button variant="dark" size="lg" onClick={handleGetStarted} className="mt-3">
            Server jetzt mieten
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default Home;
