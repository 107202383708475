import React, { useState } from 'react';
import { Container, Card, Form, Table, Row, Col, Button } from 'react-bootstrap';
import RangeSlider from 'react-bootstrap-range-slider';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import { useNavigate } from 'react-router-dom';

const Preise = () => {
  const [ram, setRam] = useState(4);
  const [storage, setStorage] = useState(100);
  const [cpu, setCpu] = useState(1);
  const navigate = useNavigate();

  const totalCost = (ram * 2) + (storage / 25) + (cpu * 5);

  const handlePackageSelect = (packageName) => {
    navigate('/erstellen', { state: { packageName } });
  };

  const packageDetails = [
    { name: 'Bronze', price: 5, ram: 1, storage: 20, cpu: 1, description: 'Für bis zu 5 Spieler geeignet' },
    { name: 'Silber', price: 10, ram: 2, storage: 50, cpu: 2, description: 'Für bis zu 10 Spieler geeignet' },
    { name: 'Gold', price: 15, ram: 3, storage: 75, cpu: 3, description: 'Für bis zu 15 Spieler geeignet' }
  ];

  const advancedPackageDetails = [
    { name: 'Advanced Bronze', price: 10, ram: 4, storage: 100, cpu: 2, description: 'Für bis zu 20 Spieler geeignet' },
    { name: 'Advanced Silber', price: 15, ram: 5, storage: 125, cpu: 3, description: 'Für bis zu 30 Spieler geeignet' },
    { name: 'Advanced Gold', price: 20, ram: 6, storage: 150, cpu: 4, description: 'Für bis zu 40 Spieler geeignet' }
  ];

  return (
    <Container className="py-5">
      <Row className="justify-content-center">
        <Col md={8}>
          <h1 className="fw-bold my-4">Preise</h1>

          <Card className="mb-4">
            <Card.Header>
              <h2>Standard Pakete</h2>
            </Card.Header>
            <Card.Body>
              <Row>
                {packageDetails.map((pkg) => (
                  <Col md={4} key={pkg.name} className="mb-4">
                    <Card onClick={() => handlePackageSelect(pkg.name)} className="text-center" style={{ cursor: 'pointer' }}>
                      <Card.Body>
                        <Card.Title>{pkg.name}</Card.Title>
                        <p>{pkg.price} € / Monat</p>
                        <p><strong>{pkg.description}</strong></p>
                        <Card.Text>
                          <ul className="list-unstyled">
                            <li>{pkg.ram} GB RAM</li>
                            <li>{pkg.storage} GB Speicher</li>
                            <li>{pkg.cpu} vCPU</li>
                          </ul>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Card.Body>
          </Card>

          <Card className="mb-4">
            <Card.Header>
              <h2>Advanced Pakete</h2>
            </Card.Header>
            <Card.Body>
              <Row>
                {advancedPackageDetails.map((pkg) => (
                  <Col md={4} key={pkg.name} className="mb-4">
                    <Card onClick={() => handlePackageSelect(pkg.name)} className="text-center" style={{ cursor: 'pointer' }}>
                      <Card.Body>
                        <Card.Title>{pkg.name}</Card.Title>
                        <p>{pkg.price} € / Monat</p>
                        <p><strong>{pkg.description}</strong></p>
                        <Card.Text>
                          <ul className="list-unstyled">
                            <li>{pkg.ram} GB RAM</li>
                            <li>{pkg.storage} GB Speicher</li>
                            <li>{pkg.cpu} vCPU</li>
                          </ul>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Card.Body>
          </Card>

          <Card className="mb-5">
            <Card.Header>
              <h2>Individuell</h2>
            </Card.Header>
            <Card.Body>
              <Form>
                <Form.Group controlId="formRam">
                  <Form.Label>Arbeitsspeicher (GB): {ram}</Form.Label>
                  <RangeSlider
                    value={ram}
                    onChange={(e) => setRam(parseInt(e.target.value))}
                    min={1}
                    max={16}
                    tooltip='auto'
                  />
                </Form.Group>

                <Form.Group controlId="formStorage" className="mt-3">
                  <Form.Label>Speicher (GB): {storage}</Form.Label>
                  <RangeSlider
                    value={storage}
                    onChange={(e) => setStorage(parseInt(e.target.value))}
                    min={25}
                    max={500}
                    step={25}
                    tooltip='auto'
                  />
                </Form.Group>

                <Form.Group controlId="formCpu" className="mt-3">
                  <Form.Label>CPU-Kerne: {cpu}</Form.Label>
                  <RangeSlider
                    value={cpu}
                    onChange={(e) => setCpu(parseInt(e.target.value))}
                    min={1}
                    max={8}
                    tooltip='auto'
                  />
                </Form.Group>
              </Form>
              <h5 className="mt-3">Gesamtkosten: {totalCost.toFixed(2)} € / Monat</h5>
            </Card.Body>
          </Card>

          <Card>
            <Card.Header>
              <h2>Features</h2>
            </Card.Header>
            <Card.Body>
              <Table bordered>
                <thead>
                  <tr>
                    <th></th>
                    <th>Basic</th>
                    <th>Advanced</th>
                    <th>Individuell</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Processor</td>
                    <td colSpan="3">AMD Epyc 7351P, 2.4 GHz / 2.9 GHz</td>
                  </tr>
                  <tr>
                    <td>Anti DDoS</td>
                    <td colSpan="3">L3/L4 Schutz</td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Preise;
