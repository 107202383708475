import React, { useEffect, useState } from 'react';
import { Card, Container, Row, Col, Table, Spinner, Alert, Form, Button } from 'react-bootstrap';
import axios from 'axios';

const Transactions = () => {
  const isDebugging = process.env.NODE_ENV === 'development';
  const BackendAddress = isDebugging ? 'http://localhost:3001' : 'https://backendtest.dein-game-server.de';

  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [oneTimeAmount, setOneTimeAmount] = useState('');
  const [voucherCode, setVoucherCode] = useState('');
  const [success, setSuccess] = useState('');
  const [balance, setBalance] = useState(0);

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          setError('Benutzer nicht authentifiziert.');
          setLoading(false);
          return;
        }

        const response = await axios.get(`${BackendAddress}/api/balance/all-transactions`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        const sortedTransactions = response.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        setTransactions(sortedTransactions);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching transactions:', error);
        setError('Fehler beim Laden der Transaktionen.');
        setLoading(false);
      }
    };

    const fetchBalance = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${BackendAddress}/api/balance/balance`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        setBalance(parseFloat(response.data) || 0);
      } catch (err) {
        setError('Fehler beim Abrufen des Guthabens.');
      }
    };

    fetchBalance();
    fetchTransactions();
  }, [BackendAddress]);

  const handleOneTimePayment = () => {
    const token = localStorage.getItem('token');

    axios.post(`${BackendAddress}/api/bezahlen/zahlung-erstellen`, 
      { amount: oneTimeAmount },
      {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      .then(response => {
        window.open(response.data.url, '_blank');
      })
      .catch(error => {
        console.error('Error creating one-time payment:', error);
        setError('Fehler beim Erstellen der Zahlung.');
      });
  };

  const handleRedeemVoucher = async () => {
    const token = localStorage.getItem('token');

    try {
      const response = await axios.post(
        `${BackendAddress}/api/balance/redeem-voucher`,
        { code: voucherCode },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSuccess(`Gutschein eingelöst: +${response.data.amount} €.`);
      setBalance(balance + parseFloat(response.data.amount));
      setVoucherCode('');
    } catch (err) {
      setError(err.response?.data?.message || 'Gutschein konnte nicht eingelöst werden.');
    }
  };

  return (
    <Container className="py-5">
      <Row className="justify-content-center">
        <Col md={8}>
          <Card className="mb-4">
            <Card.Header>
              <h3>Guthaben und Aufladung</h3>
            </Card.Header>
            <Card.Body>
              <h4>Kontostand: {balance.toFixed(2)} €</h4>
              {error && <Alert variant="danger">{error}</Alert>}
              {success && <Alert variant="success">{success}</Alert>}

              <Form>
                <Form.Group className="mb-3">
                  <Form.Label>Betrag aufladen</Form.Label>
                  <Form.Control
                    type="number"
                    value={oneTimeAmount}
                    onChange={(e) => setOneTimeAmount(e.target.value)}
                    placeholder="Betrag eingeben"
                  />
                  <Button variant="primary" className="mt-3" onClick={handleOneTimePayment}>
                    Jetzt zahlen
                  </Button>
                </Form.Group>

                <Form.Group className="mt-4">
                  <Form.Label>Gutscheincode einlösen</Form.Label>
                  <Form.Control
                    type="text"
                    value={voucherCode}
                    onChange={(e) => setVoucherCode(e.target.value)}
                    placeholder="Gutscheincode eingeben"
                  />
                  <Button variant="success" className="mt-3" onClick={handleRedeemVoucher}>
                    Gutschein einlösen
                  </Button>
                </Form.Group>
              </Form>
            </Card.Body>
          </Card>

          <Card>
            <Card.Header>
              <h3>Meine Transaktionen</h3>
            </Card.Header>
            <Card.Body>
              {loading ? (
                <div className="text-center">
                  <Spinner animation="border" />
                </div>
              ) : (
                <Table striped bordered hover responsive>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Betrag</th>
                      <th>Grund</th>
                      <th>Datum</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {transactions.length > 0 ? (
                      transactions.map((transaction) => (
                        <tr key={transaction.id}>
                          <td>{transaction.id}</td>
                          <td>{parseFloat(transaction.amount || 0).toFixed(2)} €</td>
                          <td>{transaction.reason}</td>
                          <td>{new Date(transaction.created_at).toLocaleString()}</td>
                          <td>
                            {transaction.is_completed ? (
                              <span className="text-success">Abgeschlossen</span>
                            ) : (
                              <span className="text-warning">Ausstehend</span>
                            )}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="5" className="text-center">
                          Keine Transaktionen vorhanden.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Transactions;
