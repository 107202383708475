import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container, Card, Form, Button, Row, Col, Alert } from 'react-bootstrap';
import axios from 'axios';
import RangeSlider from 'react-bootstrap-range-slider';
import { ArrowLeft } from 'react-bootstrap-icons';

const Erstellen = () => {
  const isDebugging = process.env.NODE_ENV === 'development';
  const BackendAddress = isDebugging ? 'http://localhost:3001' : 'https://backendtest.dein-game-server.de';
  const location = useLocation();
  const navigate = useNavigate();
  const [packageName, setPackageName] = useState('');
  const [userBalance, setUserBalance] = useState(0);
  const [error, setError] = useState(null);
  const [packagePrice, setPackagePrice] = useState(0);

  const [ram, setRam] = useState(4);
  const [storage, setStorage] = useState(100);
  const [cpu, setCpu] = useState(1);

  const packagePrices = {
    'Bronze': 5,
    'Silber': 10,
    'Gold': 15,
    'Advanced Bronze': 10,
    'Advanced Silber': 15,
    'Advanced Gold': 20,
  };

  const calculateCustomPackagePrice = () => {
    return (ram * 2) + (storage / 20) + (cpu * 5);
  };

  useEffect(() => {
    if (location.state && location.state.packageName) {
      setPackageName(location.state.packageName);
      updatePackagePrice(location.state.packageName);
    }

    const fetchBalance = async () => {
      const token = localStorage.getItem('token');

      try {
        const response = await axios.get(`${BackendAddress}/api/balance/balance`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUserBalance(parseFloat(response.data) || 0);
      } catch (err) {
        setError('Fehler beim Abrufen des Guthabens.');
      }
    };
    fetchBalance();
  }, [location, ram, storage, cpu]);

  const updatePackagePrice = (selectedPackage) => {
    if (selectedPackage === 'Individuell') {
      setPackagePrice(calculateCustomPackagePrice());
    } else {
      setPackagePrice(packagePrices[selectedPackage] || 0);
    }
  };

  const handlePackageSelect = (selectedPackage) => {
    setPackageName(selectedPackage);
    updatePackagePrice(selectedPackage);
  };

  const handleServerCreation = async () => {
    const token = localStorage.getItem('token');

    const requestData = {
      packageName,
      ram,
      storage,
      cpu,
    };

    if (userBalance >= packagePrice) {
      try {
        await axios.post(`${BackendAddress}/api/erstellen/erstellen`, requestData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        navigate('/server');
      } catch (error) {
        console.error('Fehler bei der Erstellung des Servers:', error);
        setError('Fehler bei der Erstellung des Servers.');
      }
    } else {
      setError('Nicht genug Guthaben!');
    }
  };

  return (
    <Container className="py-5">
      {packageName ? (
        <Row className="justify-content-center">
          <Col md={8}>
            <Card className="mb-4">
              <Card.Header>
                <Button variant="link" onClick={() => setPackageName('')} className="p-0">
                  <ArrowLeft size={24} /> Zurück zu den Paketen
                </Button>
              </Card.Header>
              <Card.Body>
                {error && <Alert variant="danger">{error}</Alert>}
                <Form>
                  <Form.Group className="mb-3">
                    <Form.Label>Gewähltes Paket: {packageName}</Form.Label>
                  </Form.Group>

                  {packageName === 'Individuell' && (
                    <>
                      <Form.Group className="mt-3">
                        <Form.Label>Arbeitsspeicher (GB): {ram}</Form.Label>
                        <RangeSlider
                          value={ram}
                          onChange={(e) => {
                            const value = parseInt(e.target.value);
                            setRam(value);
                            setPackagePrice(calculateCustomPackagePrice());
                          }}
                          min={1}
                          max={16}
                        />
                      </Form.Group>

                      <Form.Group className="mt-3">
                        <Form.Label>Speicher (GB): {storage}</Form.Label>
                        <RangeSlider
                          value={storage}
                          onChange={(e) => {
                            const value = parseInt(e.target.value);
                            setStorage(value);
                            setPackagePrice(calculateCustomPackagePrice());
                          }}
                          min={25}
                          max={500}
                          step={25}
                        />
                      </Form.Group>

                      <Form.Group className="mt-3">
                        <Form.Label>CPU-Kerne: {cpu}</Form.Label>
                        <RangeSlider
                          value={cpu}
                          onChange={(e) => {
                            const value = parseInt(e.target.value);
                            setCpu(value);
                            setPackagePrice(calculateCustomPackagePrice());
                          }}
                          min={1}
                          max={8}
                        />
                      </Form.Group>
                    </>
                  )}

                  <h5 className="mt-3">Preis des Pakets: {packagePrice.toFixed(2)} €</h5>
                  <h5>Verfügbares Guthaben: {userBalance.toFixed(2)} €</h5>
                </Form>
              </Card.Body>
              <Card.Footer>
                <Button
                  variant="primary"
                  onClick={handleServerCreation}
                  disabled={!packageName}
                >
                  Server erstellen
                </Button>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      ) : (
        <Row className="justify-content-center">
          {Object.entries({ ...packagePrices, 'Individuell': 'Flexibel' }).map(([pkg, price]) => (
            <Col md={4} key={pkg} className="mb-4">
              <Card onClick={() => handlePackageSelect(pkg)} className="text-center" style={{ cursor: 'pointer' }}>
                <Card.Body>
                  <Card.Title>{pkg}</Card.Title>
                  <Card.Text>
                    {pkg === 'Individuell' ? 'Passe deinen Server individuell an' : `${price.toFixed(2)} € / Monat`}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      )}
    </Container>
  );
};

export default Erstellen;
